import React, { FC, useCallback } from 'react';
import { CountrySelectorModal } from '@/components/landing/CountrySelectorModal';
import { Button } from '@/components/ui/Button';
import { IconWorld } from '@/components/ui/icons/IconWorld';
import { useTranslation } from '@/middleware/i18n';
import { I18N_CODES } from '@/lib/constants/general';
import { IconChevronDown } from '@/components/ui/icons/IconChevronDown';
import { cn } from '@/lib';
import { Dropdown } from '@/components/common/Dropdown';
import { Selectors } from '@/lib/selectors';
import { DomainSelectorModal } from '@/components/landing/LandingHeader/components/HeaderLanguageSelector/DomainSelectorModal';
import { IconRoundCountry } from '@/components/landing/LandingHeader/components/HeaderLanguageSelector/icons/IconRoundCountry';
import { useFeatures } from '@/controllers/features/features.hooks/useFeatures';
import { Features } from '@/controllers/features/features.constants';
import { useIsMobileWidth } from '@/hooks/useIsMobileWidth';
import { MobileLanguageSelector } from '@/components/landing/LandingHeader/components/HeaderLanguageSelector/MobileLanguageSelector';
import { useIsOpenState } from '@/hooks/useIsOpenState';
import styles from './HeaderLanguageSelector.module.scss';

export const HeaderLanguageSelector: FC = () => {
  const { t } = useTranslation([I18N_CODES.common]);
  const features = useFeatures();
  const isMobile = useIsMobileWidth();
  const { isOpen, toggle } = useIsOpenState();

  const dropdownRenderTrigger = useCallback((onClick) => (
    <Button
      onClick={onClick}
      LeftIcon={IconRoundCountry}
      RightIcon={IconChevronDown}
      data-qa="header-change-domain-button"
      size={Button.size.Large}
      mode={Button.mode.TransparentLight}
      className={styles.languageSelector}
    />
  ), []);

  const dropdownRenderContent = useCallback(({ isActive }) => (
    <div
      data-qa="header-change-domain-dropdown"
      className={cn(
        styles.modal,
        { [Selectors.DropdownOpened]: isActive },
      )}
    >
      {isActive && <DomainSelectorModal />}
    </div>
  ), []);

  const isRedesignEnabled = features
    .isEnabled(Features.CountrySelectorRedesign);

  if (isRedesignEnabled) {
    if (isMobile) {
      return (
        <MobileLanguageSelector
          isOpen={isOpen}
          toggleModal={toggle}
        />
      );
    }

    return (
      <Dropdown
        isFullWidth
        renderContent={dropdownRenderContent}
        renderTrigger={dropdownRenderTrigger}
      />
    );
  }

  return (
    <CountrySelectorModal
      renderTriggerButton={({ onClick }) => (
        <Button
          data-qa="header-change-language"
          size={Button.size.Large}
          mode={Button.mode.TransparentLight}
          title={t(`${I18N_CODES.common}:change_language`)}
          LeftIcon={IconWorld}
          onClick={onClick}
        />
      )}
    />
  );
};
