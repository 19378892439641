import React, { FC } from 'react';
import { FCIcon, SvgProps } from '@/components/ui/icons/typedefs';
import { useSubDomainContext } from '@/controllers/subDomain/subDomain.hooks/useSubDomainContext';
import { SubDomains } from '@/constants/subdomains';
import { IconFlagBR } from '@/components/landing/LandingHeader/components/HeaderLanguageSelector/icons/IconFlagBR';
import { IconFlagPL } from '@/components/landing/LandingHeader/components/HeaderLanguageSelector/icons/IconFlagPL';
import { IconFlagUA } from '@/components/landing/LandingHeader/components/HeaderLanguageSelector/icons/IconFlagUA';
import { IconWorld } from '@/components/ui/icons/IconWorld';
import styles from '../HeaderLanguageSelector.module.scss';

export const subDomainToCountryIconMapper: Record<SubDomains, FCIcon> = {
  [SubDomains.br]: IconFlagBR,
  [SubDomains.pl]: IconFlagPL,
  [SubDomains.ua]: IconFlagUA,
  [SubDomains.us]: IconWorld,
  [SubDomains.usa]: IconWorld,
  [SubDomains.uk]: IconWorld,
  [SubDomains.eu]: IconWorld,
};

interface Props {
  country?: SubDomains | string;
}

export const IconRoundCountry: FC<SvgProps & Props> = ({ country }) => {
  const { subDomain } = useSubDomainContext();
  const domainKey = (country || subDomain) as SubDomains;
  const CountryIcon = subDomainToCountryIconMapper[domainKey];

  return (
    <div className={styles.icon}>
      {
        CountryIcon
          ? <CountryIcon />
          : <IconWorld />
      }
    </div>
  );
};
