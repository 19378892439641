import React, { FC } from 'react';
import getConfig from 'next/config';
import { cn } from '@/lib/classNames';
import { typography } from '@/components/ui/typography';
import { Link, useTranslation } from '@/middleware/i18n';
import { AppEnvironments, I18N_CODES } from '@/lib/constants/general';
import { UserAvatar } from '@/components/ui/UserAvatar';
import { LandingHeaderLogoutButton } from '@/components/landing/LandingHeader/components/LandingHeaderLogoutButton';
import { useFeatures } from '@/controllers/features/features.hooks/useFeatures';
import { Features } from '@/controllers/features/features.constants';
import { AuthUserFragment } from '@/controllers/user/graphql/generated/AuthUser.fragment.generated';
import { useLearningRootForRedirect } from '@/components/platform/Study/pages/urlTools';
import styles from './HeaderProfileAuthLink.module.scss';

export interface Props {
  authUser: AuthUserFragment;
  shouldShowTooltip?: boolean;
}

export const HeaderProfileAuthLink: FC<Props> = (props) => {
  const {
    authUser,
    shouldShowTooltip,
  } = props;
  const { t } = useTranslation([I18N_CODES.common]);

  const username = (
    authUser.firstName && authUser.lastName
      ? `${authUser.firstName} ${authUser.lastName}`
      : authUser.email
  );

  const { publicRuntimeConfig = {} } = getConfig() || {};
  const { APP_ENV } = publicRuntimeConfig;
  const features = useFeatures();

  const shouldRenderLogoutButton = (
    features.isEnabled(Features.LandingHeaderLogoutButton)
    && APP_ENV !== AppEnvironments.Production
  );
  const learningRoot = useLearningRootForRedirect();

  return (
    <Link
      href={learningRoot}
      passHref
      aria-label={t(`${I18N_CODES.common}:cabinet_link`)}
    >
      <div className={cn(styles.userIconWrapper)}>
        {shouldRenderLogoutButton && (
          <LandingHeaderLogoutButton />
        )}

        <UserAvatar
          fullName={username}
          userId={authUser.id}
          avatar={authUser.avatar}
          badgeType={authUser.badgeType}
          size={40}
          shouldOpenProfile={false}
        />

        {shouldShowTooltip && (
          <span
            className={cn(
              styles.authUserName,
              typography.platformH4,
              'auth-user-name',
            )}
          >
            {t(`${I18N_CODES.common}:profile_auth_link_tooltip`)}
          </span>
        )}
      </div>
    </Link>
  );
};
