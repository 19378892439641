export const COURSE_BLOCKS = {
  heroSection: 'hero-section',
  coverForm: 'cover-form',
  aboutAs: 'about-us',
  englishClub: 'english-club',
  signUpForm: 'sign-up-form',
  courseList: 'course-list',
  allCourses: 'all-courses',
  teamMembers: 'course-team-members',
  program: 'course-program',
  price: 'price',
  reviews: 'reviews',
  howItWorks: 'how-it-works',
  consultationForm: 'consultation-form',
  fullTimePricing: 'full-time-pricing',
  partnerInfo: 'partner-info',
  courseStudentsPortfolio: 'course-students-portfolio',
  courseSchedule: 'course-schedule',
  aboutProfession: 'about-profession',
  agreement: 'agreement',
  advantages: 'advantages',
  technologies: 'technologies',
  author: 'author',
  benefits: 'benefits',
  employmentGuarantee: 'employment-guarantee',
  employersReviews: 'employers-reviews',
  companies: 'companies',
  applicationFlow: 'application-flow',
  otherCourses: 'other-courses',
  forWhom: 'for-whom',
  learningSteps: 'learning-steps',
  fullTimeCoursePrices: 'full-time-course-prices',
  partTimeCoursePrices: 'part-time-course-prices',
  faq: 'faq',
  platformDemo: 'platform-demo',
  compareFormats: 'compare-formats',
  newYearCards: 'new-year-cards',
  aboutPlatform: 'about-platform',
};
